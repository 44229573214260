<template>
	<div style="background: white;width: 1200px; margin: 10px auto;">
		<!-- banner 
		<div class="banner">
			关于我们
		</div>-->
		<!-- 人才保障
		<div class="service-c">
			<div class="service">
				<div class="title">兰溪人才网简介</div>
				<div class="serviceCont">
					<div v-html="serviceinfo.cCONTENTS"></div>
				</div>
			</div>
		</div> -->
		<!-- 应用通道 -->
		<div class="honor">
			<div class="title">联系我们</div>
			<div class="honorCont">
				<div v-html="honorinfo.cCONTENTS"></div>
			</div>
		</div>
	</div>
</template>
<script>
	import {
		Getnewsbyid,
	} from "@/api/home";
	import utils from "@/assets/js/utils";
	export default {
		data() {
			return {
				serviceinfo: {},
				honorinfo: {},
			};
		},
		created() {
			this.getservice();
			this.gethonor();
		},
		methods: {
			getservice() {
				var _this = this;
				var par = {
					id: 3186
				};
				Getnewsbyid(par).then((res) => {
					if (res.success) {
						_this.serviceinfo = res.data;
					} else {
						_this.$message.error(res.msg);
					}
				});
			},
			gethonor() {
				var _this = this;
				var par = {
					id: 2785
				};
				Getnewsbyid(par).then((res) => {
					if (res.success) {
						_this.honorinfo = res.data;
					} else {
						_this.$message.error(res.msg);
					}
				});
			},
		},
	};
</script>
<style lang="scss" scoped>
	// banner
	.banner {
		width: 100%;
		height: 180px;
		background: url(../../assets/img/RCservice.png) center;
		display: flex;
		justify-content: center;
		align-items: center;
		color: #fff;
		font-size: 36px;
		font-weight: bold;
	}

	// 企业产品与服务
	.service-c {
		width: 100%;
		padding: 20px 0;
	}

	.service {
		padding: 38px 40px;

		.title {
			font-size: 26px;
			font-weight: bold;
			color: #101010;
		}

		.serviceCont {
			font-size: 14px;
			color: #898989;
		}
	}

	// 部分所获荣誉
	.honor {
		padding: 40px;
		box-sizing: border-box;

		.title {
			line-height: 48px;
			border-left: 5px solid #0089ff;
			background-color: #eee;
			font-size: 18px;
			color: #0089FF;
			padding-left: 32px;
		}

		.honorCont {
			font-size: 15px;
			color: #333
		}
	}
</style>
